import request from '@/utils/request'
const adStpeApi = {
  // 添加广告计划
  addAdPlan: '/admanage/addAdPlan',
  // 编辑广告计划
  updateAdplan: '/admanage/updateAdplan',
  // 获取广告计划列表
  getAdPlanList: '/admanage/ad_plan_list',
  // 获取应用-->广告位树形数据列表
  getAppPlaceTree: '/admanage/getAppPlaceInfo',
  // 获取安装列表（app定向）
  getDirectionalSettingAppList: '/admanage/getDirectionalSettingAppList',
  // 获取手机品牌列表
  getPhoneBrandList: '/common/brandList',
  // 添加广告组
  addAdPlanGroup: '/admanage/addAdPlanGroup',
  // 编辑广告组
  updateAdPlanGroup: '/admanage/updateAdPlanGroup',
  // 获取广告组下来列表
  getAdGroupList: '/admanage/ad_plan_group_list',
  // 获取广告组详情
  getAdPlanGroupInfoByPlanGroupId: '/admanage/getAdPlanGroupInfoByPlanGroupId',
  // 新增广告创意
  addAdCreative: '/admanage/addAd',
  // 通过广告id查广告详情
  getAdByAdId: '/admanage/getAdByAdId',
  // 编辑广告
  updateAdCreative: '/admanage/updateAd',
  // 获取广告组回显详情(文字)
  getDirectionalByGroupId: '/admanage/getDirectionalByGroupId'
}

export default adStpeApi
// 添加广告计划
export function addAdPlan (data) {
  return request({
    url: adStpeApi.addAdPlan,
    method: 'post',
    data
  })
}
// 编辑广告计划
export function updateAdplan (data) {
  return request({
    url: adStpeApi.updateAdplan,
    method: 'post',
    data
  })
}
// 获取广告计划列表
export function getAdPlanList () {
  return request({
    url: adStpeApi.getAdPlanList,
    method: 'get'
  })
}
// 获取应用-->广告位树形数据列表
export function getAppPlaceTree () {
  return request({
    url: adStpeApi.getAppPlaceTree,
    method: 'post'
  })
}
// 获取安装列表（app定向）
export function getDirectionalInstallAppList () {
  return request({
    url: adStpeApi.getDirectionalSettingAppList,
    method: 'get'
  })
}
// 获取手机品牌列表
export function getPhoneBrandList () {
  return request({
    url: adStpeApi.getPhoneBrandList,
    method: 'get'
  })
}
// 添加广告组
export function addAdPlanGroup (data) {
  return request({
    url: adStpeApi.addAdPlanGroup,
    method: 'post',
    data
  })
}
// 编辑广告组
export function updateAdPlanGroup (data) {
  return request({
    url: adStpeApi.updateAdPlanGroup,
    method: 'post',
    data
  })
}
// 获取广告组下来列表
export function getAdGroupList (data) {
  return request({
    url: adStpeApi.getAdGroupList,
    method: 'post',
    data
  })
}
// 获取广告组详情
export function getAdPlanGroupInfoByPlanGroupId (data) {
  return request({
    url: adStpeApi.getAdPlanGroupInfoByPlanGroupId,
    method: 'post',
    data
  })
}
// 新增广告创意
export function addAdCreative (data) {
  return request({
    url: adStpeApi.addAdCreative,
    method: 'post',
    data
  })
}
// 通过广告id查广告详情
export function getAdByAdId (data) {
  return request({
    url: adStpeApi.getAdByAdId,
    method: 'post',
    data
  })
}
// 编辑广告
export function updateAdCreative (data) {
  return request({
    url: adStpeApi.updateAdCreative,
    method: 'post',
    data
  })
}
// 获取广告组回显详情(文字)
export function getDirectionalByGroupId (data) {
  return request({
    url: adStpeApi.getDirectionalByGroupId,
    method: 'post',
    data
  })
}
