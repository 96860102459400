import request from '@/utils/request'
const basrUrl = '/creative'
// 获取联盟广告列表
export function getCreativeList (data) {
  return request({
    url: `${basrUrl}/pager`,
    method: 'post',
    data
  })
}
// 广告主 下拉框
export function advertiserList (data) {
  return request({
    url: `/adPlan/advertiserList`,
    method: 'post',
    data
  })
}
// 新增基本信息
export function addAdPlan (data) {
  return request({
    url: `/adPlan/addAdPlan`,
    method: 'post',
    data
  })
}
// 新增定向信息
export function directionalSetting (data) {
  return request({
    url: `/adPlan/directionalSetting`,
    method: 'post',
    data
  })
}
// 广告位列表
export function getAppPlaceInfo (data) {
  return request({
    url: `/adPlan/getAppPlaceInfo`,
    method: 'post',
    data
  })
}
// 新增广告位
export function putOnPlace (data) {
  return request({
    url: `/adPlan/putOnPlace`,
    method: 'post',
    data
  })
}
// 上传创意
export function uploadCreative (data) {
  return request({
    url: `/adPlan/uploadCreative`,
    method: 'post',
    data
  })
}

// 直客广告列表
export function adPlanPager (data) {
  return request({
    url: `/adPlan/adPlanPager`,
    method: 'post',
    data
  })
}

// 直客广告计划列表
export function adplanpagerList (data) {
  return request({
    url: `/admanage/ad_plan_pager`,
    method: 'post',
    data
  })
}

// 直客广告组列表
export function adGroupPagerList (data) {
  return request({
    url: `/admanage/ad_group_pager`,
    method: 'post',
    data
  })
}

// 广告列表
export function adPagerList (data) {
  return request({
    url: `/admanage/ad_pager`,
    method: 'post',
    data
  })
}

// 创意列表
export function creativeList (data) {
  return request({
    url: `/admanage/creative_list`,
    method: 'post',
    data
  })
}

// 直客广告计划列表批量操作
export function batchOperationSts (data) {
  return request({
    url: `/admanage/batchOperationSts`,
    method: 'post',
    data
  })
}

// 广告计划更新接口
export function updateAdplan (data) {
  return request({
    url: `/admanage/updateAdplan`,
    method: 'post',
    data
  })
}

// 广告组更新接口
export function updateAdPlanGroupInfo (data) {
  return request({
    url: `/admanage/updateAdPlanGroupInfo`,
    method: 'post',
    data
  })
}

// 广告更新接口
export function updateAdInfo (data) {
  return request({
    url: `/admanage/updateAdInfo`,
    method: 'post',
    data
  })
}

// 获取广告联盟pager数据
export function getPlatPager (data) {
  return request({
    url: `plat/pager`,
    method: 'post',
    data
  })
}
// 联盟广告修改广告平台
export function updatePlat (data) {
  return request({
    url: `/plat/update`,
    method: 'post',
    data
  })
}
// 自动运营修改广告平台
export function updatePlatForAutoOperator (data) {
  return request({
    url: `/plat/autoOperatorUpdate`,
    method: 'post',
    data
  })
}

// 联盟广告添加广告平台
export function addPlat (data) {
  return request({
    url: `/plat/add`,
    method: 'post',
    data
  })
}

// 联盟广告添加自定义广告平台
export function addDefinedPlat (data) {
  return request({
    url: `/plat/addDefinedPlat`,
    method: 'post',
    data
  })
}

// 根据广告计划id查询计划详情
export function getAdPlanById (data) {
  return request({
    url: `/adPlan/getAdPlanById`,
    method: 'post',
    data
  })
}
// 根据广告计划id查询投放广告位详情
export function getAdPlanPutOnPlaceById (data) {
  return request({
    url: `/adPlan/getAdPlanPutOnPlaceById`,
    method: 'post',
    data
  })
}
// 根据广告计划id查询投放广告位详情
export function getAdPlanDirectionalSettingById (data) {
  return request({
    url: `/adPlan/getAdPlanDirectionalSettingById`,
    method: 'post',
    data
  })
}
// 根据广告计划id查询素材详情
export function getAdPlanCreativeById (data) {
  return request({
    url: `/adPlan/getAdPlanCreativeById`,
    method: 'post',
    data
  })
}
// 编辑基本信息
export function updateAdPlan (data) {
  return request({
    url: `/adPlan/updateAdPlan`,
    method: 'post',
    data
  })
}
// 编辑投放广告位
export function updatePutOnPlace (data) {
  return request({
    url: `/adPlan/updatePutOnPlace`,
    method: 'post',
    data
  })
}
// 编辑上传创意
export function updateCreative (data) {
  return request({
    url: `/adPlan/updateCreative`,
    method: 'post',
    data
  })
}
// 广告计划下拉框
export function adPlanList (data) {
  return request({
    url: `/adPlan/adPlanList`,
    method: 'post',
    data
  })
}
// 编辑定向设置
export function updateDirectionalSetting (data) {
  return request({
    url: `/adPlan/updateDirectionalSetting`,
    method: 'post',
    data
  })
}
export function uploadFile (data) {
  return request({
    url: `/adPlan/uploadFile`,
    method: 'post',
    data
  })
}
// 修改计划状态
export function updateAdPlanSts (data) {
  return request({
    url: `/adPlan/updateAdPlanSts`,
    method: 'post',
    data
  })
}
// 修改素材状态
export function updateAdPlanCreativeSts (data) {
  return request({
    url: `/adPlan/updateAdPlanCreativeSts`,
    method: 'post',
    data
  })
}

// 通过广告主id查计划列表
export function getAdplanByAdvertiserIds (data) {
  return request({
    url: `/adPlan/getAdplanByAdvertiserIds`,
    method: 'post',
    data
  })
}

// 根据登录用户获取 平台+账户+app
export function getAccountList (data) {
  return request({
    url: `/plat/accountList`,
    method: 'post',
    data
  })
}

// 获取广告源列表
export function getAdsourcePager (data) {
  return request({
    url: `/plat/adsourcePager`,
    method: 'post',
    data
  })
}

// 编辑广告源
export function getUpdateAdsource (data) {
  return request({
    url: `/plat/updateAdsource`,
    method: 'post',
    data
  })
}

// s删除广告源
export function getDeleteAdsource (data) {
  return request({
    url: `/plat/deleteAdsource`,
    method: 'post',
    data
  })
}

// 修改platappid
export function updatePlatAppId (data) {
  return request({
    url: `/plat/updatePlatAppId`,
    method: 'post',
    data
  })
}
// 素材监测弹窗
export function platformListForCreative () {
  return request({
    url: `/plat/platformListForCreative`,
    method: 'get'
  })
}
// 素材监测编辑
export function updateCreativeSts (data) {
  return request({
    url: `/plat/updateCreativeSts`,
    method: 'post',
    data
  })
}
// 修改素材替换设置
export function updateCreativeReplaceSetting (data) {
  return request({
    url: `/place/updateCreativeReplaceSetting`,
    method: 'post',
    data
  })
}
// 查询素材替换设置
export function getCreativeReplaceSetting (data) {
  return request({
    url: `/place/getCreativeReplaceSetting`,
    method: 'post',
    data
  })
}
// 修改授权状态
export function updateAuthSts (data) {
  return request({
    url: `/plat/updateAuthSts`,
    method: 'post',
    data
  })
}
